<template>
    <div class="layout-list-header pr16">
        <input type="text" placeholder="Search" name="search" class="noborder nopadding pl16" ref="searchbox" @input="onInput">
        <select v-model="selectedSort" name="" id="" class="noborder nopadding pl8" @change="onSort">
            <option value="select" selected disabled>Ordina per</option>
            <option value="Nome asc">Nome asc</option>
            <option value="Nome desc">Nome desc</option>
            <option value="data creazione asc">data creazione asc</option>
            <option value="data creazione desc">data creazione  desc</option>
            <option v-if="isCampaign" value="Nome Advertiser asc">Nome Advertiser asc</option>
            <option v-if="isCampaign" value="Nome Advertiser desc">Nome Advertiser desc</option>

        </select>
    </div>
</template>
<script>
import {ref} from 'vue'
import {debounce} from '@/utils/TimeFunctions'
export default {
    emits:['change', 'input'],
    props:{
        isCampaign:{
            type : Boolean,
            default:false
        },
        defaultSort: {
            type: String,
            default: null
        }
    },
    
    data() {
        return {
            selectedSort: this.defaultSort
        }
    },

    mounted() {
        if (this.defaultSort) {
            this.onSort({ target: { value: this.defaultSort } });
        }
    },
    
    setup(_, {emit}){
        const searchbox = ref(null)
        const onSort =(e)=>{            
            const sorting = {
                type:e.target.value,
            }
            emit('change', sorting)
        }

        const onInput = debounce(()=> {
            const filter = {
                type:'search',
                value:searchbox.value.value
            }
            emit('input', filter)
        }, 250)
        return{
            onSort,
            onInput,
            searchbox,
            debounce
        }
    }
}
</script>