<template>
    <div class="layout-list-footer f12">
        <infloo-button
            :className='"button pagination pl8 pr8"'
            @click="$emit('page-change',1)"
            :disabled="pagination.CurrentPage == 1"
        >
            <i class="lni lni-angle-double-left"></i>
        </infloo-button>
        <infloo-button
            :className='"button pagination pl8 pr8 prev"'
            @click="$emit('page-change',pagination.CurrentPage - 1)"
            :disabled="pagination.CurrentPage == 1"
        >
           <i class="lni lni-chevron-left"></i>
        </infloo-button>
        <div class="flex-container numbers">
            pagina 
            <input
                :value="pagination.CurrentPage"
                name="page"
                type="number" 
                min="1"
                :max="pagination.TotalPages" 
                step="any"
                class="noborder nopadding m8"
                @input="pageChange"
            />
            <span>di {{pagination.TotalPages}}</span>
        </div>
        <infloo-button
            :className='"button pagination pl8 pr8 next"'
            @click="$emit('page-change',pagination.CurrentPage + 1)"
            :disabled="pagination.CurrentPage == pagination.TotalPages"
        >
            <i class="lni lni-chevron-right"></i>
        </infloo-button> 
        <infloo-button
            :className='"button pagination pl8 pr8"'
            @click="$emit('page-change',pagination.TotalPages)"
            :disabled="pagination.CurrentPage == pagination.TotalPages"
        >
            <i class="lni lni-angle-double-right"></i>
        </infloo-button>
    </div>
</template>
<script>
import InflooButton from '@/components/buttons/InflooButton'
export default {
    emits:['page-change'],
    components:{
        InflooButton
    },
    props:{
        pagination:{
            type:Object,
            default:()=>{}
        }
    },
    setup(props, {emit}){
       
        const pageChange = (e)=>{    
            if(e.target.value  !== ''){
                emit('page-change', e.target.value)
            }
        }
        return{
            pageChange
        }
    }
    
}
</script>
<style lang="postcss">
.layout-list-footer{
    & .numbers{
        & input {
            width:40px;
            padding-left: .5em;
            & :focus{
                height: initial;
            }
        }
    }
    & .prev{
        margin-left: 0;
        margin-right: auto;
    }
    & .next{
        margin-right: 0;
        margin-left: auto;
    }
}
</style>